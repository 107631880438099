const cookieconsent = require('vanilla-cookieconsent');
import '../../../node_modules/vanilla-cookieconsent/dist/cookieconsent.css';
                
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

let cc = initCookieConsent();
cc.run({
    current_lang: 'cz',
    autoclear_cookies: true,
    page_scripts: true,
    gui_options: {
        consent_modal: {
            layout: 'bar',               // box/cloud/bar
            position: 'bottom center',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'box',                 // box/bar
            // position: 'left',           // left/right
            transition: 'slide'            // zoom/slide
        }
    },
    
    onAccept: function(cookie) {
        if (!cookie.level) {
            return;
        }
        cookie.level.forEach(function(level) {
            if (level == 'ad_storage') {
                gtag('consent', 'update', {
                    'ad_storage': 'granted',
                    'ad_user_data': 'granted',
                    'ad_personalization': 'granted'
                });
            }

            if (level == 'analytics_storage') {
                gtag('consent', 'update', {
                    'analytics_storage': 'granted'
                });
            }
        });
    },

    languages: {
        'cz': {
            consent_modal: {
                title: 'Tato webová stránka používá cookies',
                description: 'Cookies 🍪 milujeme, a to nejen ty k jídlu. Tyto datové soubory nám pomáhají vylepšovat služby a usnadňovat Vám průchod naším webem. To díky nim se nemusíte pokaždé přihlašovat. Dovolíte nám cookies používat?',
                primary_btn: {
                    text: 'Povolit vše',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Nastavení',
                    role: 'settings'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Nastavení souborů cookie',
                save_settings_btn: 'Uložit nastavení',
                accept_all_btn: 'Povolit vše',
                reject_all_btn: 'Zakázat vše',
                close_btn_label: 'Zavřít',
                blocks: [
                    {
                        title: 'Nutné',
                        description: 'Nutné cookies pomáhají, aby byla webová stránka použitelná tak, že umožní základní funkce jako navigace stránky a přístup k zabezpečeným sekcím webové stránky. Webová stránka nemůže správně fungovat bez těchto cookies.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'Statistické',
                        description: 'Slouží k anonymnímu sběru informací, abychom lépe pochopili, jak se stránkami pracujete',
                        toggle: {
                            value: 'analytics_storage',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                    }, {
                        title: 'Marketingové',
                        description: 'Marketingové cookies jsou používány pro sledování návštěvníků na webových stránkách. Záměrem je zobrazit reklamu, která je relevantní a zajímavá pro jednotlivého uživatele a tímto hodnotnější pro vydavatele a inzerenty třetích stran.',
                        toggle: {
                            value: 'ad_storage',
                            enabled: false,
                            readonly: false
                        }
                    }
                ]
            }
        }
    }
});

const newSetCookies = document.getElementById('new-set-cookies');

if (newSetCookies) {
    newSetCookies.addEventListener('click', function() {
        cc.showSettings();
    });
}
