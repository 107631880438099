
const enterFullscreen = (elem) => {
	if (elem.webkitRequestFullscreen) {
		elem.webkitRequestFullscreen();
	} else if (elem.mozRequestFullScreen) {
		elem.mozRequestFullScreen();
	} else if (elem.msRequestFullscreen) {
		elem.msRequestFullscreen();
	} else if (elem.requestFullscreen) {
		elem.requestFullscreen();
	}
}

const exitFullscreen = () => {
	if (document.webkitExitFullscreen) {
		document.webkitExitFullscreen();
	} else if (document.mozCancelFullScreen) {
		document.mozCancelFullScreen();
	} else if (document.exitFullscreen) {
		document.exitFullscreen();
	}
}

export const mediaDetail = () => {

	if (!document.getElementById('activity-multimedia')) {
		return;
	}

	$('.maximize-pdf').on('click', function () {
		var elem = $(this).prev('.pdf-container');

		elem.addClass('fullscreen');

		enterFullscreen(elem[0]);

		return false;
	});

	$('.minimize-pdf').on('click', function () {
		var elem = $(this).parent().parent('.pdf-container');

		elem.removeClass('fullscreen');

		exitFullscreen();

		return false;
	});

	$('.pdf-container').on('webkitfullscreenchange mozfullscreenchange fullscreenchange', function () {
		var state = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
		if (!state) {
			$(this).removeClass('fullscreen');
		}
	});
}