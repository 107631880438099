const $ = require('jquery');
window.$ = window.jQuery = $;

require('./plugins/jquery.fancybox.min');
require('./plugins/jquery.ba-dotimeout.min');
// require('../../../node_modules/bootstrap/dist/js/bootstrap.bundle');
require('../../../node_modules/bootstrap5/dist/js/bootstrap.bundle');
require('./plugins/flickity.pkgd.min');
require('./plugins/jquery.waypoints.min');
require('./plugins/jquery.scrollto');
require('flickity-sync');
require('./cookieconsent');

require('./main');
const gameApp = require('../js-game/game');
const mediaDetail = require('./custom/media.detail');
const codeLogin = require('./custom/code.login');
const registrationCodeLogin = require('./custom/registration.code.login');
require('./tippy');

$(() => {
    $('[data-loader]').each((_, el) => {
        $(el).closest('form').submit(() => {
            $(el).prop('disabled', true);
            $(el).prepend('<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>');
        });
    })

    $('.toast').toast();
    $('.toast').each((_, el) => {
        $(el).toast('show');
    });
    $('[data-toggle="tooltip"]').tooltip();

    $('.search-box *')
        .focusin(() => {
            $('.search-box').addClass('focus');
            $('.intro').addClass('overflow');
        });

    $('.intro__form.focusable *')
        .focusin(() => {
            $('.intro__form').addClass('focus');
        });

    $('.intro__form.hintable *')
        .focusin(() => {
            $('.intro__form').addClass('showhint');
        })
        .focusout(() => {
            window.setTimeout(() => {
                $('.intro__form').removeClass('showhint');
            }, 300);
        });

    $('.search-box--backdrop').click(() => {
        $('.search-box').removeClass('focus');
        $('.intro').removeClass('overflow');
        $('.intro__form').removeClass('showhint');
    });

    $('.intro__form--backdrop').click(() => {
        $('.intro__form').removeClass('focus');
        $('.intro__form').removeClass('showhint');
    });

    $('.btn-notification-close').on('click', (ev) => {
        var uuid = ev.currentTarget.getAttribute('uuid');
        document.cookie = `closed_notification=${uuid}; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure`;

        $('.head-notification').fadeOut(500, () => {
            $('.head-notification').hide()
        });
    });

    $('#run-activity').click((e) => {
        var apiActivityVisitUrl = $(e.target).data('api-activity-visit-url');
        var apiActivityRecommendationsUrl = $(e.target).data('api-activity-recommendations-url');
        e.preventDefault();

        $('#activity-overlay').hide();
        const activityIframeContainer = document.getElementById("activity-iframe");

        if ((activityIframeContainer instanceof HTMLElement) && (typeof GameApi !== 'undefined')) {

            const ifr = document.createElement('iframe');

            ifr.setAttribute('src', GameApi.iframeSrc);
            ifr.setAttribute('id', 'exerciseFrame')
            ifr.setAttribute('scrolling', 'no');
            ifr.setAttribute('frameborder', '0');
            ifr.setAttribute('allowfullscreen', true);

            activityIframeContainer.append(ifr);
            activityIframeContainer.style.display = "block";

            const gameId = window.location.pathname.split('/').pop();
            gameApp.gameApp(gameId, GameApi, ifr, apiActivityVisitUrl).then(() => {

                if (!apiActivityRecommendationsUrl) {
                    return;
                }

                fetch(apiActivityRecommendationsUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                }).then((resp) => {
                    resp.json()
                        .then(data => {
                            window.postMessage({type: "newRecommendations", data: data});
                        });
                })
                    .catch((err) => {
                        reject(err);
                        console.error({err});
                    });
            });
        }

    });

    $('#mail-addr-form').submit((e) => {
        e.preventDefault();

        const form = $('#mail-addr-form');
        const formData = form.serialize();

        $.post(emailCollectUrl, formData, (data) => {
            if (data && data.valid === true) {
                $.fancybox.open($('#email-notice'));
            }
        });
    });

    $('#mail-addr-form-popup').submit((e) => {
        e.preventDefault();

        const form = $('#mail-addr-form-popup');
        const formData = form.serialize();

        $.post(emailCollectUrl, formData, (data) => {
            $.fancybox.close(true);
            if (data && data.valid === true) {
                $.fancybox.open($('#email-notice'));
            }
        });
    });

    const repositionFilter = () => {
        const filter = $('#filter');
        const activityList = $('#activity-list');
        const activityListOffset = activityList.offset();

        if (filter && activityList && activityListOffset) {
            const filterOffsetTop = Math.round(activityListOffset.top + activityList.outerHeight());

            if (filterOffsetTop > Math.round($(window).scrollTop() + $(window).height())) {
                filter.addClass('sticky');
            } else {
                filter.removeClass('sticky');
            }
        }
    };

    const saveScroll = () => {
        const url = new URL(window.location);
        if (url.searchParams.has('scroll')) {
            url.searchParams.delete('scroll');
        }

        window.history.replaceState({
            scroll: Math.round($(window).scrollTop())
        }, document.title, url.href);
    };

    const restoreScroll = () => {
        const url = new URL(window.location);

        if (url.searchParams.has('scroll')) {
            $(window).scrollTop(url.searchParams.get('scroll'));
        } else {
            const state = window.history.state;
            if (state && state.scroll) {
                $(window).scrollTop(state.scroll);
            }
        }
    };

    const scrollToLastAdded = (numFromEnd, options) => {
        const elementsCard = document.querySelectorAll('#activity-list .card');

        if (elementsCard.length === 0) {
            return;
        }

        const elementScrollToIndex = elementsCard.length > numFromEnd ?
            elementsCard.length - numFromEnd :
            0;
        const elementScrollTo = elementsCard[elementScrollToIndex];

        elementScrollTo.scrollIntoView(options);
    };

    const scrollToBeginning = (options) => {
        if (document.documentElement.scrollTop > 480) {
            const elementActivityList = document.getElementById('filtered-activity-list');

            elementActivityList.scrollIntoView(options);
        }
    };

    window.repositionFilter = repositionFilter;
    window.saveScroll = saveScroll;
    window.restoreScroll = restoreScroll;
    window.scrollToLastAdded = scrollToLastAdded;
    window.scrollToBeginning = scrollToBeginning;

    $('header .nav-item--sub').hover(() => {
        $('.navbar-overlay').delay(500).show();
        $('.navbar-overlay').click(() => {
            $('header .nav-item--sub').off("hover");
        });
    }, () => {
        $('.navbar-overlay').hide();
    });

    repositionFilter();

    $(window).scroll(() => {
        repositionFilter();
    });

    codeLogin.codeLogin('code-login-inputs');

    mediaDetail.mediaDetail();
    registrationCodeLogin.registrationCodeLogin();

    const hpRegInfoKeys = ['teacher', 'parent', 'student'];
    let currentHpRegInfoKey = 'teacher';

    const showHpRegInfo = (key) => {
        hpRegInfoKeys.map((key) => {
            $('#hp-reg-info-' + key).hide();
            $('#hp-reg-btnpremium-' + key).hide();
            $('#hp-reg-button-' + key).removeClass("btn-black").addClass("btn-secondary");
        });

        $('#hp-reg-info-' + key).show();
        $('#hp-reg-btnpremium-' + key).show();
        $('#hp-reg-button-' + key).addClass("btn-black").removeClass("btn-secondary");
        currentHpRegInfoKey = key;
    };

    hpRegInfoKeys.map((key) => {
        $('#hp-reg-button-' + key).click(() => {
            showHpRegInfo(key);
        });
    });

    $('#hp-reg-button-next').click(() => {
        let indexKey = hpRegInfoKeys.indexOf(currentHpRegInfoKey) + 1;

        if (!hpRegInfoKeys[indexKey]) {
            indexKey = 0;
        }

        showHpRegInfo(hpRegInfoKeys[indexKey]);
    });

    showHpRegInfo(currentHpRegInfoKey);

    $('.video-reference').hide();

    $('#play-wutka-reference').click(() => {
        $('#video-wutka-reference').show();
        $('#play-wutka-reference').hide();
        $('#video-wutka-reference')[0].play();
    });

    if ($('#video-wutka-reference').length) {
        $('#video-wutka-reference')[0].addEventListener('ended', (event) => {
            $('#video-wutka-reference').hide();
            $('#play-wutka-reference').show();
        });
    }

    if ($("input[name='activation[monsterColor]']")) {
        $("input[name='activation[monsterColor]']").change(() => {
            let color = $("input[name='activation[monsterColor]']:checked").val();
            $(".img-monster").each((index, el) => {
                let src = $(el).data('src');
                $(el).attr('src', src.replace('(selectColor)', color));
            });
        });
    }
});
