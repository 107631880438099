export const gameApp = (gameId, GameApi, activityIframe, apiActivityVisitUrl, memberAssignmentId = null, presentactionMode = 1, exerciseState = null) => {

    return new Promise((resolve, reject) => {
        const jsonResponse = {
            data: {
                visitId: null,
                activityId: null,
            }
        };

        const setExerciseGame = () => {
            let paylodSetExercise = GameApi.exerciseData.activity;
            paylodSetExercise.presentationMode = presentactionMode;

            activityIframe.contentWindow.postMessage({
                type: 'SetExercise',
                payload: GameApi.exerciseData.activity
            }, GameApi.targetOrigin);

            activityIframe.contentWindow.postMessage({
                type: 'SetStyle',
                payload: GameApi.exerciseData.exerciseStyle
            }, GameApi.targetOrigin);

            if (exerciseState) {
                sendExerciseState(exerciseState);
            }

            if (presentactionMode === 1) {
                fetch(apiActivityVisitUrl + '/' + gameId, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                })
                    .then((resp) => {
                        resp.json()
                            .then(data => ({
                                data: data,
                                status: resp.status
                            }))
                            .then(r => {
                                if (r.data) {
                                    jsonResponse.data = {...r.data}
                                }
                            });
                    })
                    .catch((err) => {
                        reject(err);
                        console.error({err});
                    });
            }
        }

        const saveState = (state) => {
            const payload = JSON.stringify({
                state: state,
                activityId: gameId,
                recommendationId: GameApi.recommendationId,
                visitId: jsonResponse.data.visitId
            });

            if (presentactionMode === 1) {
                fetch(GameApi.saveStateUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: payload,
                }).catch((err) => {
                    reject(err);
                    console.error({err});
                });
            }
        };

        const saveFinished = (state) => {
            const results = JSON.stringify({
                visitId: jsonResponse.data.visitId,
                duration: state.duration ? parseInt(state.duration) / 1000.0 : null,
                totalSuccessRate: state.hasOwnProperty('score') ? parseFloat(state.score.totalSuccessRate) : null,
                memberAssignment: memberAssignmentId
            });

            if (!jsonResponse.data.activityId) {
                return resolve();
            }

            if (presentactionMode === 1) {
                fetch(apiActivityVisitUrl + '/' + jsonResponse.data.activityId, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: results,
                })
                    .then((resp) => {
                        resp.json()
                            .then(data => ({
                                data: data,
                                status: resp.status
                            }));
                        resolve();
                        window.postMessage({type: "achievementNotifies"});
                    })
                    .catch((err) => {
                        reject(err);
                        console.error({err});
                    });
            }
        };

        const sendExerciseState = (exerciseState) => {
            activityIframe.contentWindow.postMessage({
                type: 'SetExerciseState',
                payload: exerciseState
            }, GameApi.targetOrigin);
        };

        if (window.frMessageListener) {
            window.removeEventListener('message', window.frMessageListener);
        }

        window.frMessageListener = (ev) => {
            if (ev.data.type === 'SendExerciseState') {
                saveState(ev.data.payload);
            } else if (ev.data.type === 'ExerciseFinished') {
                saveFinished(ev.data.payload);
            } else if (ev.data === 'MessageListenerInitialized') {
                setExerciseGame();
            }
        };

        window.addEventListener('message', window.frMessageListener);

    });

};