export const registrationCodeLogin = () => {

	const checkbox = document.getElementById('_link_with_account');
	const lwaon = document.getElementById('_link_with_account_on');
	const enter = document.getElementById('enter-without-registration');

	if (!checkbox) {
		return;
	}

	checkbox.addEventListener('change', (ev) => {
		if (ev.target.checked) {
			lwaon.style.display = 'block';
			enter.style.display = 'none';
		} else {
			lwaon.style.display = 'none';
			enter.style.display = 'block';
		}
	});
};