require('@popperjs/core');
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

$(document).ready(() => {
    tippy('[data-tippy-content]', {
        allowHTML: true,
        interactiveBorder: 20
    });
})
