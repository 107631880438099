const LEFT = 37;
const RIGHT = 39;
const DELETE = 'Delete';
const BACKSPACE = 'Backspace';

const preventAndStop = (ev) => {
	ev.preventDefault();
	ev.stopPropagation();
}

export const writeHandler = (ev, n) => {
	if (ev.keyCode === undefined) {
		return;
	}

	if (ev.keyCode == LEFT && n.prev !== null) {
		n.prev.focus();
		preventAndStop(ev);
		return;
	}

	if (ev.keyCode == RIGHT && n.next !== null) {
		n.next.focus();
		preventAndStop(ev);
		return;
	}

	if (ev.key === undefined) {
		return;
	}

	if (ev.key == BACKSPACE) {
		let val = n.value;

		n.value = '';

		if (n.prev !== null && !val) {
			n.prev.focus();
		}
		preventAndStop(ev);
		return;
	}

	if (ev.key == DELETE) {
		n.value = '';
		preventAndStop(ev);
		return;
	}

	let key = ev.key.toUpperCase();

	if (key === 'V' && ev.ctrlKey) {
		return;
	}

	if (!(ev.ctrlKey || ev.altKey) && key.length == 1) {
		n.value = key;
		if (n.next !== null) {
			n.next.focus();
		}
		preventAndStop(ev);
	}
};

export const pasteHandler = (ev, n) => {
	var text = window.clipboardData ? window.clipboardData.getData("Text") : (ev.clipboardData ? ev.clipboardData.getData("text/plain") : "");

	if (!text || text === '') {
		return;
	}

	text = text.trim().toUpperCase();

	let x = 0;
	let i = n.first;
	do {
		i.value = text.charAt(x);
		x++;
	}
	while (i = i.next);

	preventAndStop(ev);
	return;
}

export const codeLogin = (inputsParentId) => {
	const parent = document.getElementById(inputsParentId);

	if (!parent) {
		return;
	}

	const nums = parent.querySelectorAll('input[name^=_code_part]');

	let prev = null;
	let first = null;

	for (const n of nums) {
		if (!first) {
			first = n;
		}

		if (prev) {
			prev.next = n;
		}

		n.prev = prev;
		n.next = null;
		n.first = first;
		n.addEventListener('paste', (ev) => { pasteHandler(ev, n); });
		n.addEventListener('keydown', (ev) => { writeHandler(ev, n); });

		prev = n;
	}
};